@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-9250px);
  }
}

.slider {
  height: 150px;
  width: 100%;
  background: #fff;
  margin: 4rem auto auto;
  position: relative;
  overflow: hidden;
}

.slider:before, .slider:after {
  content: "";
  height: 150px;
  width: 200px;
  z-index: 2;
  background: linear-gradient(to right, #fff 0%, #fff0 100%);
  position: absolute;
}

.slider:after {
  top: 0;
  right: 0;
  transform: rotateZ(180deg);
}

.slider:before {
  top: 0;
  left: 0;
}

.slider .slide-track {
  width: 18500px;
  animation: 33s linear infinite scroll;
  display: flex;
}

.slider .slide {
  height: 150px;
  width: 250px;
}

.slider .slide img {
  object-fit: cover;
  max-width: 100%;
}

/*# sourceMappingURL=index.ecfeb4b1.css.map */
